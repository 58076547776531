<template>
  <div class="subscribe-form">
    <!-- Start Coupon Code -->
    <label class="font-bold text-right">هل لديك كود خصم ؟</label>
    <div
    class="gift-input"
    :class="{'defult':codeStatus==null,'success':codeStatus,'error':codeStatus==false}">
      <input
        id="coupon-code"
        type="text"
        v-model="couponCode"
        @input="inputHandler"
        placeholder="كود الخصم ان وجد"
        />

      <img :src='"assets/images/icons/gift.png"' class="gift-icon"/>
    </div>
    <!-- End Coupon Code -->

    <stripe-checkout
      ref="checkoutRef"
      :pk="publishableKey"
      :session-id="sessionId"
    />

    <!-- Start Plan Card -->
    <div class="plan-card" v-if="selectedPlan">
      <h1 class="font-bold text-right d-flex justify-content-end align-items-center">
        {{ selectedPlan.text }}
        <span class="dolar-sign" >ر.س </span>
        <span class="mr-2" v-if="discount>0">{{total}} / </span>
        <span :class="{'strikethrough':discount>0}" >{{ selectedPlan.price }}</span>
      </h1>
      <p class="text-right">{{ selectedPlan.desc }}</p>
      <!-- <span class="plan-price font-bold d-flex justify-content-end">
        <span> {{selectedPlan.text}} </span>
        <span class="dolar-sign" >ر.س </span>
        <span > {{ selectedPlan.price }} </span> 
      </span> -->

      <template v-if="this.action == 'upgrade'">
        <button
          type="button"
          class="font-bold mt-40"
          @click="upgradeSubscription"
          >ترقية
        </button>
      </template>
      <template v-else>
        <button
          type="button"
          class="font-bold mt-40"
          @click="handleSubscribe"
          >
          اشتراك
        </button>
      </template>
    </div>
    <!-- End Plan Card -->

    <div class="note">
      <p>يمكنك الغاء الاشتراك في اي وقت
        <a class="font-bold text-orange cursor-pointer"
        @click="$router.push('/p/terms')">الشروط و الأحكام</a>
      </p>
    </div>
  </div>
</template>
<script>
import simpleDebounce from '@/mixin.js'
import {verifyCoupon, getProfile, subscribe,upgradeSubscription, createStripeCheckoutSession} from '@/apis/services'

import { STRIPE_PUBLIC_KEY } from "@/config/index";
import { StripeCheckout } from '@vue-stripe/vue-stripe';
export default {
  components: { StripeCheckout },
  data() {
    return {
      codeStatus: null,
      discount: 0,
      openLightBox: true,
      publishableKey: STRIPE_PUBLIC_KEY,
      sessionId:'',
      action: null
    }
  },
  mixins: [simpleDebounce],
  computed: {
    homeInfo(){
      return this.$store.state.homeInfo
    },
    user () {
			return this.$store.state.user
		},
    isAuthenticated () {
			return this.user
		},
    selectedPlan() {
      return this.$store.state.selectedPlan
    },
    couponCode: {
      get() {
          return this.$store.state.couponCode
      },
      set(value) {
          this.$store.commit('UPDATE_COUPON_CODE', value)
      }
    },
    coupon: {
      get() {
          return this.$store.state.coupon
      },
      set(value) {
          this.$store.commit('UPDATE_COUPON', value)
      }
    },
    payment_method: {
      get() {
          return this.$store.state.payment_method
      },
      set(value) {
          this.$store.commit('UPDATE_PAYMENT_METHOD', value)
      }
    },
    total() {
      return (this.selectedPlan?.price - this.discount).toFixed(2);
    },
	},
  watch: {
    total(val) {
      this.$emit('updateTotal',val)
    }
  },
  methods: {
    setLoading(event) {
      this.$emit('setLoading',event)
    },
    getData() {
      if (this.action == "upgrade") {
        let homeInfo = this.homeInfo;
        let plan = homeInfo.pricingPlans.plans.find(
            plan => plan.type == "YEARLY"
        );
        this.$store.commit("SET_SELECTED_PLAN", plan);
      }
    },
    inputHandler($event) {
      this.simpleDebounce(() => {
          this.handleCouponCode()
      }, 350 /* optional debounce time: default is 300 */)
    },
    handleCouponCode() {
      try {
        let query = { ...this.$route.query, coupon_code: this.couponCode };
        if(!this.couponCode){
            delete query.coupon_code;
        }
        if(this.$route.query.coupon_code !== this.couponCode) {
          this.$router.replace({query: query});
        }
        this.codeStatus = null
        verifyCoupon(this.couponCode, this.selectedPlan.id).then(res => {
          if (res) {
            this.codeStatus = res.data.success
            this.coupon = res.data.data;
            let type = res.data.data.calculation
            if (this.coupon?.is_free) {
                this.discount = this.selectedPlan.price;
            } else if (type == 'Constant') {
                this.discount = res.data.data.discount
            } else if (type == 'Percentage') {
                this.discount = this.selectedPlan.price * (res.data.data.discount / 100)
            }
            if (!res.data.success) {
              this.discount = 0
            }
            this.discount = this.discount.toFixed(2);
          }
        }).catch(e => {
        })
      } catch (e) {
      }
    },
    handleSubscribe() {
      if (this.isAuthenticated) {
        let query = { ...this.$route.query, payment_method: this.payment_method?.id };
        this.$router.push({ query: query });
        if (this.coupon?.is_free) {
          this.subscribe();
        } else {
            this.createCheckoutSession();
        }
      } else {
        this.$emit('showLoginForm')
      }
    },
    createCheckoutSession(){
      let data = {
          subscription_plan: this.selectedPlan.product_id,
          couponCode: this.couponCode,
          payment_method: this.payment_method?.type
      };
      this.setLoading(true);
      createStripeCheckoutSession(data).then(res=>{
        if (!res.data.success) {
          this.setLoading(false);
          this.$store.commit("SET_MESSAGE", {
            message: res.data.message,
            errors: res.data.data,
            status: res.data.success
          });
        } else if (!res.data.data.url) {
          this.setLoading(false);
          this.$store.commit("SET_MESSAGE", {
            message: `Sorry, ${data.payment_method} is unable to approve this purchase. Please use an alternative payment method for your order.`,
            errors: `Sorry, ${data.payment_method} is unable to approve this purchase. Please use an alternative payment method for your order.`,
            status: "error"
          });
        } else {
          this.sessionId = res.data.data.id;
          window.location.href = res.data.data.url
        }
        this.setLoading(false);
      }).catch( e => {
         this.setLoading(false);
      }).finally(() => {
        this.setLoading(false);
      });
    },
    subscribe() {
      try {
        let data = {
          subscription_plan: this.selectedPlan.product_id,
          couponCode: this.couponCode,
          payment_method: this.payment_method?.type
        };
        this.setLoading(true);
        subscribe(data)
          .then(res => {
            if (res) {
              this.$gtm.trackEvent({
                event: "subscribe",
                action: "Subscribe",
                label: "Subscribe Test",
                ...data,
                success: res.data.success
              });
              this.setLoading(false);
              let status = res.data.success ? "success" : "failed";

              this.$router.push({
                path: "/confirmation-page",
                query: { status: status }
              });

            }
          })
          .catch(e => {
            this.setLoading(false);
            this.$gtm.trackEvent({
              event: "subscribe",
              action: "Subscribe",
              label: "Subscribe Test",
              category: "Subscription",
              ...data,
              success: false
            });
          });
      } catch (e) {
        this.setLoading(false);
      }
    },
    getProfile(callback) {
      getProfile()
        .then(res => {
          if (res.data.data.subscription) {
            let is_active = res.data.data.subscription.is_active;
            if (is_active) {
              this.$router.push({ path: "/my-profile" })
              .catch(() => {

              }
              );
            }
          }
          callback();
        })
        .catch(e => {
        });
    },
    upgradeSubscription() {
      try {
        let data = {
          subscription_plan: this.selectedPlan.product_id,
          couponCode: this.couponCode,
          payment_method: this.payment_method?.type
        };
        this.setLoading(true);
        upgradeSubscription(data)
          .then(res => {
            if (res) {
              this.setLoading(false);
              this.$store.commit("SET_MESSAGE", {
                message: res.data.message,
                status: res.data.success
              });
              this.$router.push({ path: "/my-profile" });
            }
          })
          .catch(e => {
            this.setLoading(false);
          });
      } catch (e) {
        this.setLoading(false);
      }
    },
    getCouponCode() {
      let couponCode = this.$route.query.coupon_code;
      if (couponCode) {
        this.couponCode = couponCode;
        this.handleCouponCode();
      }
      if (!this.couponCode) {
        const coupon_code = JSON.parse(localStorage.getItem('couponCode'));
        if (coupon_code) {
            this.$store.commit('UPDATE_COUPON_CODE', coupon_code)
            this.handleCouponCode()
        }
      }
    },
    getSelectedPlan() {
      let productId = this.$route.query.product_id;
      if (productId) {
        const plan = this.homeInfo?.pricingPlans?.plans?.find(plan => plan.product_id == productId);
        this.$store.commit('SET_SELECTED_PLAN', plan)
      }
    },
    checkUpgrade() {
      if (this.$route.query) {
        if (this.$route.query.upgrade) {
          this.action = "upgrade";
        }
      }
    }
  },
  async mounted() {
    this.getSelectedPlan()
    this.checkUpgrade()
    this.getData();
    this.getCouponCode()
    if (this.user) {
      if (this.action !== "upgrade") {
        this.getProfile();
      }
    }
    this.$emit('updateTotal', this.total)
  }
}
</script>
<style scoped>
.dolar-sign {
  margin: 0 4px;
}
.strikethrough {
  position: relative;
  font-size: 16px;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid red;
  -webkit-transform:rotate(-40deg);
  -moz-transform:rotate(-40deg);
  -ms-transform:rotate(-40deg);
  -o-transform:rotate(-40deg);
  transform:rotate(-40deg);
}
</style>