<template>
  <div class="payment-method">
    <h1 class="payment-title font-bold text-center">اختر طريقة الدفع المناسبة لك</h1>
    <div class="payment-methods d-flex justify-content-center align-items-start">
      <template v-for="(payment,index) in filteredPaymentMethods">
        <div 
          :key="index"
          class="payment-box" @click="payment_method=payment">
          <img class="p-4" :src="'assets/images/'+payment.icon"/>
          <span :class="{ 'dot': true , active: payment_method==payment }"></span>
        </div>
      </template>
    </div>
     <div class="payment-desc" v-if="payment_method">
        <template v-if="payment_method.description">
          <p class="desc-content">{{payment_method.description}}</p>
        </template>
        <template v-if="payment_method.img">
          <div class="payment-img">
            <ul>  
              <li>
                <div class="d-flex justify-content-center">
                  <span class='circle'>
                    <span class='circle-full'>
                    </span>
                  </span>
                </div>
                <div>
                <div class="payment-price d-flex justify-content-center font-bold text-center">
                  <span>ر.س</span>
                  <span>{{monthTotal}}</span>
                </div>
                <p class="month d-flex justify-content-center">بعد 3 اشهر</p>
                </div>
              </li>
              <li>
                <div class="d-flex justify-content-center">
                  <span class="circle">
                     <span class='circle-three-quarter'>
                    </span>
                  </span>
                </div>
                <div class="w-100">
                  <div class="payment-price d-flex justify-content-center font-bold text-center">
                    <span>ر.س</span>
                    <span>{{monthTotal}}</span>
                  </div>
                  <p class="month d-flex justify-content-center">بعد شهرين</p>
                </div>
              </li>
              <li>
                <div class="d-flex justify-content-center">
                  <span class="circle">
                     <span class='circle-half'>
                    </span>
                  </span>
                </div>
                <div>
                  <div class="payment-price d-flex justify-content-center font-bold text-center">
                    <span>ر.س</span>
                    <span>{{monthTotal}}</span>
                  </div>
                  <p class="month d-flex justify-content-center">بعد شهر</p>
                </div>
              </li>
              <li>
                <div class="d-flex justify-content-center">
                <span class="circle">
                  <span class='circle-quarter'>
                  </span>
                </span>
                </div>
                <div>
                  <div class="payment-price d-flex justify-content-center font-bold text-center">
                    <span>ر.س</span>
                    <span>{{monthTotal}}</span>
                  </div>
                  <p class="month d-flex justify-content-center">اليوم</p>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </div>
    
  </div>
</template>
<script>
export default {
  props:['total'],
  data() {
    return {
      paymentMethods: [
        {
          id: 1,
          name: 'mastercard',
          type: 'stripe',
          icon: 'mastercard.png',
          description: null,
          img: null,
          planTypes: ['YEARLY','MONTHLY']
        },
        {
          id: 2,
          name: 'apple-pay',
          icon: 'apple-pay.png',
          type: 'stripe',
          description: null,
          img: null,
          planTypes: ['YEARLY','MONTHLY']
        },
        {
          id: 3,
          name: 'tamara',
          icon: 'tamara.png',
          type: 'tamara',
          description: null,
          img: null,
          planTypes: ['YEARLY']
        },
/*        {
          id: 4,
          name: 'tabby',
          icon: 'tabby.svg',
          type: 'tabby',
          description:'.تقسيط على ٤ شهور بدون عمولة',
          img: 'assets/images/tabby-img.PNG',
          planTypes: ['YEARLY']
        }
*/      ]
    }
  },
  computed: {
    payment_method: {
      get() {
          return this.$store.state.payment_method
      },
      set(value) {
          this.$store.commit('UPDATE_PAYMENT_METHOD', value)
      }
    },
    monthTotal(){
      return (this.total/4).toFixed(2)
    },
    selectedPlan() {
      return this.$store.state.selectedPlan
    },
    filteredPaymentMethods() {
      return this.paymentMethods.filter( el => el.planTypes.includes(this.selectedPlan?.type))
    }
  },
  methods: {
    handlePaymentChange(payment) {
      this.payment_name = payment.name
      this.payment_method = payment.type
    }
  },
  mounted() {
    let payment_method = this.$route.query.payment_method;
    if (payment_method) {
      this.payment_method = this.paymentMethods.find( payment => payment.id == payment_method);
    } else {
      this.payment_method = this.paymentMethods[0]
    }
  }
}
</script>
<style scoped>
.payment-img p {
  font-size: 12px;
}
.circle {
  position: relative;
}
.circle span {
  position: absolute;
  content: '';
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 100px;
  left: 1px;
  top: 1px;
}
.circle::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background: #fff;
}
.circle-quarter {
    background-image:
    linear-gradient(90deg, #f6f7f8 50%, transparent 50%),
    linear-gradient(180deg, #FF7700 50%, transparent 50%);
  }
  .circle-half {
    background-image:
    linear-gradient(90deg, #f6f7f8 50%, transparent 50%),
    linear-gradient(-90deg, #FF7700 50%, transparent 50%);
  }
  .circle-three-quarter {
    background-image:
    linear-gradient(90deg, transparent 50%, #FF7700 50%),
    linear-gradient(0deg, #FF7700 50%, transparent 50%);
  }
  .circle-full {
    background: #FF7700;
  }
  .payment-img {
    max-width: 463px;
    margin: auto;
    padding: 20px;
  }
  .payment-img ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    max-width: 463px;
    padding: 0;
  }
  .payment-img ul li {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .payment-img ul li {
    flex: 1 0 0px;
  }
  .payment-img ul li .circle {
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    border: 1px solid #FF7700;
    z-index: 10;
    /* background: #FF7700; */
    position: relative;
  }
  .payment-img ul li:not(:last-of-type)::after {
    content: ' ';
    position: absolute;
    height: 1px;
    width: 50%;
    right: 0;
    top: 25px;
    transform: translateY(-50%);
    background: #FF7700;
  }
  .payment-img ul li:not(:first-of-type)::before {
    content: ' ';
    position: absolute;
    height: 1px;
    width: 50%;
    left: 0;
    top: 25px;
    transform: translateY(-50%);
    background: #FF7700;
  }
  .payment-price {
    width: 100%;
    gap: 4px;
    font-size: 15px;
  }
 @media (max-width: 700px) {
    .payment-price { 
      font-size: 12px;
    }
    .payment-img ul li .circle {
      width: 40px;
      height: 40px;
    }
    .payment-img ul li:not(:last-of-type)::after {
      top: 20px;
    }
    .payment-img ul li:not(:first-of-type)::before {
      top: 20px;
    }
    .month {
      font-size: 10px;
    }
  }
</style>
