<template>
  <div class="payment-page">
    <PaymentMethod :total='total'/>
    <SubscribeForm 
      @updateTotal='updateTotal'
      @showLoginForm='showModal=true'
      @setLoading='setLoading'/>
    <AuthPopup 
      v-if="showModal"
      @closePopup="closePopup"/>
      <loader v-if="loading" />
  </div>
</template>
<script>
import AuthPopup from '@/components/auth-popup/index.vue'
import PaymentMethod from './components/PaymentMethod.vue'
import SubscribeForm from './components/SubscribeBox.vue'
import loader from "@/components/ui/loader";

export default {
  components: {
		AuthPopup,
    PaymentMethod,
    SubscribeForm,
    loader
	},
  data() {
    return {
      showModal: false,
      loading: false,
      total: 0
    }
  },
  computed: {
		user () {
			return this.$store.state.user
		},
    isAuthenticated () {
			return this.user
		},
    homeInfo(){
      return this.$store.state.homeInfo
    }
	},
  watch: {
    $route(to, from) {
      this.showPaymentProcess();
    },
    showModal(val) {
			if (val) {
				document.body.classList.add('modal-open');
			} else {
				document.body.classList.remove('modal-open');
			}
		},
  },
  methods: {
    updateTotal(val){
      this.total = val
    },
    getPlaneId() {
      // this.$store.dispatch('getHomeInfo');
      let productId = this.$route.query.product_id;
      if (productId) {
        const plan = this.homeInfo?.pricingPlans?.plans?.find(plan => plan.product_id == productId);
        this.$store.commit('SET_SELECTED_PLAN', plan)
      }
    },
    closePopup() {
      this.showModal = false
    },
    setLoading(event) {
      this.loading = event
    },
    showPaymentProcess() {
      if (this.$route.query) {
        if (this.$route.query.payment == "done") {
          this.$store.commit("SET_MESSAGE", {
            message: "تمت عملية الدفع بنجاح",
            status: true
          });
          let query = Object.assign({}, this.$route.query);
          delete query.payment;
          setTimeout(() => {
            this.$router.replace({ query: null });
          }, 5000);
        }
      }
    },
  },
  mounted()  {
    if (!this.isAuthenticated) {
      this.showModal = true
    }
    this.getPlaneId()
    this.showPaymentProcess();
    $("html, body").animate(
      {
        scrollTop: 0
      },
      0.5
    );
  }
}
</script>